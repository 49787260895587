/* eslint-disable unicorn/prefer-dom-node-dataset, camelcase */
import React, { useEffect, useState } from 'react';

declare global {
    interface Window {
        disqus_config: any;
        page: any;
        DISQUS: any;
    }
}

const SCRIPT_SRC = 'https://tasoskakour.disqus.com/embed.js';
const SCRIPT_ID = 'dsq-embed-scr';
const DISQUS_HTML_ID = 'disqus_thread';

const addScript = () => {
    const script = document.createElement('script');
    script.src = SCRIPT_SRC;
    script.id = SCRIPT_ID;
    script.setAttribute('data-timestamp', String(Date.now()));
    (document.head || document.body).append(script);
};

const removeScript = () => {
    const script = document.querySelector(`#${SCRIPT_ID}`);
    if (script) {
        script.remove();
    }
};

const setDisqusConfig = ({
    identifier,
    language,
    title,
    url,
}: {
    identifier: string;
    language: string;
    title: string;
    url: string;
}) => {
    window.disqus_config = function () {
        this.page.url = url;
        this.page.identifier = identifier;
        this.page.language = language || 'en';
        this.page.title = title;
    };
};

const reloadDisqusInstance = () => {
    if (window?.DISQUS) {
        window.DISQUS.reset({
            reload: true,
        });
    }
};

const useLoadInstance = (
    visible: boolean,
    config: { identifier: string; language: string; title: string; url: string }
) => {
    const { identifier, language, title, url } = config;

    useEffect(() => {
        if (visible) {
            setDisqusConfig({ identifier, language, title, url });

            // Check script if already loaded
            if (document.querySelector(`#${SCRIPT_ID}`)) {
                reloadDisqusInstance();
            } else {
                addScript();
            }
        }
    }, [visible]);
};

const useRegisterObserver = (setVisible: (b: boolean) => void) => {
    useEffect(() => {
        const disqusObserver = new IntersectionObserver(
            (entries) => {
                // comments section reached
                // start loading Disqus now
                if (entries[0].isIntersecting) {
                    setVisible(true);

                    // once executed, stop observing
                    disqusObserver.disconnect();
                }
            },
            { threshold: [0] }
        );
        disqusObserver.observe(document.querySelector(`#${DISQUS_HTML_ID}`)!);
    }, []);
};

const useCleanInstance = () => {
    useEffect(() => {
        return () => {
            removeScript();
            try {
                delete window.DISQUS;
            } catch {
                window.DISQUS = undefined;
            }

            const thread = window.document.querySelector('#disqus_thread');
            if (thread) {
                while (thread.hasChildNodes()) {
                    thread?.firstChild?.remove();
                }
            }

            // Retrieve and remove the sidebar iframe
            const iframeQuery = window.document.querySelector('[id^="dsq-app"]');
            if (iframeQuery) {
                // eslint-disable-next-line unicorn/prefer-query-selector
                const iframe = window.document.getElementById(iframeQuery.id);
                iframe?.remove();
            }
        };
    }, []);
};

type Props = {
    identifier: string;
    language: string;
    title: string;
    url: string;
};

const Disqus: React.FC<Props> = (props) => {
    const { identifier, language, title, url } = props;
    const [visible, setVisible] = useState(false);

    useLoadInstance(visible, { identifier, language, title, url });
    useRegisterObserver(setVisible);
    useCleanInstance();

    return (
        <>
            <div id={DISQUS_HTML_ID} style={{ minHeight: '100px' }}>
                <div id="disqus_thread_loader" style={{ fontStyle: 'italic', marginTop: '8px' }}>
                    Loading Comments...
                </div>
            </div>
            <noscript>
                Please enable JavaScript to view the{' '}
                <a href="https://disqus.com/?ref_noscript">comments powered by Disqus.</a>
            </noscript>
        </>
    );
};

export default Disqus;
