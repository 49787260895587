/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLocalized } from 'components/LocalizedProvider';
import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const StyledBox = styled.div`
    ${tw`bg-orange-100 border border-gray-600 w-full p-3 rounded mb-3 text-gray-700`};

    & .set-language {
        ${tw`text-secondary underline cursor-pointer`}
    }
`;

// We assume only Greek is supported :-)
const DialogChangeLanguage = () => {
    const { isContentAvailableToTheBrowserLanguage, setLocale } = useLocalized();

    if (!isContentAvailableToTheBrowserLanguage) return null;

    return (
        <StyledBox>
            Αυτό το post είναι διαθέσιμο και στα{' '}
            <span className="set-language" onClick={() => setLocale({ newLocale: 'gr' })}>
                Ελληνικά
            </span>
            .
        </StyledBox>
    );
};

export default DialogChangeLanguage;
