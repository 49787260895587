import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import Newsletter, { NewsletterFrontmatter } from 'components/Newsletter';
import locales from 'constants/locales';
import LocalizedLink from 'components/LocalizedLink';
import Disqus from 'components/Disqus';
import BoxChangeLanguage from './box-change-language';
import * as Styled from './styles';

interface Post {
    html: React.ReactNode;
    frontmatter: {
        cover: {
            publicURL: string;
        };
        slug: string;
        title: string;
        date: string;
        description: string;
        tags: string[];
        extraMetaKeywords?: string[];
    };
}

interface Props extends PageProps {
    data: {
        postData: Post;
        newsletter: {
            frontmatter: NewsletterFrontmatter;
        };
    };
    pageContext: {
        slug: string;
        next: Post;
        previous: Post;
        locale: keyof typeof locales;
    };
}

const BlogPost: React.FC<Props> = ({ data: { postData, newsletter }, pageContext, location }) => {
    const post = postData;
    const { previous, next, slug, locale } = pageContext;
    const localeISO = locales[locale]?.localeISO;

    // eslint-disable-next-line unicorn/prefer-spread
    const keywords = (post.frontmatter.tags || []).concat(
        post.frontmatter?.extraMetaKeywords ?? []
    );

    return (
        <Layout locale={locale}>
            <SEO
                path={`blog/${slug}`}
                locale={locale}
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                keywords={keywords}
                imageUrl={post.frontmatter.cover.publicURL}
            />
            <Container>
                <BoxChangeLanguage />
                <TitleSection title={post.frontmatter.date} subtitle={post.frontmatter.title} />
                <FormatHtml content={post.html} />
                <Styled.NewsletterContainer>
                    <Newsletter roundedBorder data={newsletter} />
                </Styled.NewsletterContainer>

                <Styled.DisqusContainer>
                    <Disqus
                        identifier={slug}
                        language={localeISO}
                        url={location ? location.href : ''}
                        title={post.frontmatter.title}
                    />
                </Styled.DisqusContainer>
                <Styled.Links>
                    <span>
                        {previous && (
                            <LocalizedLink to={`/blog/${previous.frontmatter.slug}`} rel="previous">
                                ← {previous.frontmatter.title}
                            </LocalizedLink>
                        )}
                    </span>
                    <span>
                        {next && (
                            <LocalizedLink to={`/blog/${next.frontmatter.slug}`} rel="next">
                                {next.frontmatter.title} →
                            </LocalizedLink>
                        )}
                    </span>
                </Styled.Links>
            </Container>
        </Layout>
    );
};

export default BlogPost;

export const query = graphql`
    query BlogPostBySlug(
        $slug: String!
        $locale: String!
        $localeISO: String!
        $dateFormatString: String!
    ) {
        postData: markdownRemark(frontmatter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
            html
            frontmatter {
                title
                cover {
                    publicURL
                }
                date(formatString: $dateFormatString, locale: $localeISO)
                description
                tags
                extraMetaKeywords
            }
        }
        newsletter: markdownRemark(
            frontmatter: { category: { eq: "newsletter" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                description
                namePlaceholder
                emailPlaceholder
                submitPlaceholder
                mailchimpFormUrl
                successMessage
                alreadySubsribedMessage
                failMessage
            }
        }
    }
`;
